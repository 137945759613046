body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hand {
  font-size: xx-large;
  font-weight: bold;
}
.board {
  font-size: xx-large;
  font-weight: bold;
}

.param-form {
  width: 332px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 32px;
}
.param-form {
  background-color: #fafafa;
  text-align: left;
  justify-content: center;
}
.param-form label {
  display: block;
  text-align: left;
  font-weight: bold;
  width: 100%;
  color: #333;
}
.param-form input {
  display: block;
  font-size: 24px;
  width: 300px;
  border: 1px solid #333;
  border-radius: 4px;
  margin-left: 5px;
}
.param-form button {
  background: royalblue;
  border: none;
  padding: 4px 16px;
  border-radius: 4px;
  min-width: 80px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.form-group {
  margin-bottom: 12px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.error {
  color: red;
  font-size: 12px;
  padding: 4px;
}
